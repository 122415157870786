import React, { useContext } from "react";
import { motion } from "framer-motion";
import { FirebaseFuntions } from "../../../context/FirebaseContext";
import { Link, useNavigate } from "react-router-dom";
const Header = ({ props }) => {
  const { userId, projectId } = props;
  const { userLogout } = useContext(FirebaseFuntions);
  const navigate = useNavigate();

  return (
    <header className="flex items-center justify-end w-full bg-white p-3">
      <motion.div
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
      >
        <Link to={`/${projectId}/${userId}/edit`} title="Editar perfil">
          <svg
            width="20"
            height="20"
            fill="#1C365C"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M9.954 2.213a9.99 9.99 0 0 1 4.091-.002A3.994 3.994 0 0 0 16 5.073a3.993 3.993 0 0 0 3.457.26A9.99 9.99 0 0 1 21.5 8.88a3.994 3.994 0 0 0-1.5 3.124 3.99 3.99 0 0 0 1.502 3.124 10.042 10.042 0 0 1-2.046 3.543 3.993 3.993 0 0 0-4.76 1.468 3.993 3.993 0 0 0-.65 1.653 9.992 9.992 0 0 1-4.09.004A3.993 3.993 0 0 0 8 18.93a3.992 3.992 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.124a3.994 3.994 0 0 0 1.106-4.856 3.993 3.993 0 0 0-1.108-1.39 10.043 10.043 0 0 1 2.046-3.543 3.993 3.993 0 0 0 4.76-1.468 4 4 0 0 0 .65-1.653v-.001ZM12 15.003a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
          </svg>
        </Link>
      </motion.div>
      <motion.button
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.9 }}
        transition={{ type: "spring", stiffness: 400, damping: 17 }}
        className="bg-custlog-primary text-white text-tiny font-bold p-2 rounded-lg uppercase font-body ml-2"
        onClick={() => {
          userLogout();
          setTimeout(() => {
            navigate(`/${projectId}/${userId}`);
          }, 500);
        }}
      >
        Cerrar Sesión
      </motion.button>
    </header>
  );
};

export default Header;
