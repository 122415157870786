import React, { useMemo, useState } from "react"
import { motion } from "framer-motion"
import profile from "../../../assets/social_icons/profile.png"
import linkedinImg from "../../../assets/social_icons/linkedin.png"
import email_ from "../../../assets/social_icons/email.png"
import phoneImg from "../../../assets/social_icons/phone.png"
import whatsapp from "../../../assets/social_icons/whatsapp.png"
import facebookp from "../../../assets/social_icons/facebook.png"
import instagramp from "../../../assets/social_icons/instagram.png"
import twitterp from "../../../assets/social_icons/xlogo.png"
import videop from "../../../assets/social_icons/video.png"
import generateVcard from "../../../functions/generateVcard"
import QrModal from "../../../components/QrModal/QrModal"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import logo_oxxo from "../../../assets/templates/oxxo/logo_oxxo.png"
import microsoftteamslg from "../../../assets/social_icons/microsoftteams.png"
import contact from "../../../assets/social_icons/contact.png"


const MainCointainer = ({ user, typeOfDevice, projectLogo }) => {

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, job, video, microsoftteams, animated, linkbuttom } = user

    const [showQr, setShowQr] = useState(false)

    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])

    const handleQR = () => {
        setShowQr(!showQr)
    }

    const socialStyle = "flex flex-col md:m-6 md:w-24 sm:m-3 sm:w-3/12 drop-shadow-lg"
    const labelStyle = "mt-3 text-center sm:text-sm md:text-base font-body"


    return (
        <main className="flex flex-col justify-center">
            <section className="flex md:justify-center flex-col sm:justify-center items-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                <div className="absolute bg-oxxo-primary w-full shadow-lg top-16 border-b-8 border-oxxo-secondary h-1/5 ">
                    <motion.div
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}>
                    </motion.div>
                </div>

                <div className="w-full flex justify-center items-center md:flex-col sm:flex-col md:w-2/5 sm:w-full">
                    <div className="md:w-2/5 sm:w-full flex items-center justify-center">
                    </div>
                    <motion.div
                        initial={{ opacity: 0, scale: 0.5 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{ duration: 0.5 }}
                        className="flex flex-col items-center justify-center">
                        <img className="md:w-56 md:mt-0 sm:mt-5 sm:w-52 rounded-full mx-auto drop-shadow-lg border-4 border-oxxo-secondary" src={photo ? photo : profile} alt="profile" />
                        <motion.button
                            onClick={handleQR}
                            title="Ver QR"
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            style={{ filter: "none" }} className="flex items-center justify-center z-10 bg-oxxo-primary border-4 border-oxxo-secondary rounded-full p-2 -top-5 relative">
                            <svg width="20" height="20" fill="none" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                <path fill="#fff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                <path fill="#fff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                <path fill="#fff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                <path fill="#fff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                            </svg>
                        </motion.button>
                    </motion.div>
                    <div className="w-full flex flex-col justify-center items-center">
                        <div className="leading-tight mb-3 flex w-full flex-col justify-center items-center space-y-2">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-center font-heading">{name} {lastname}</motion.h1>
                            {
                                job &&
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center uppercase font-body">{job}</motion.h2>

                            }
                            {web &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="md:text-2xl sm:text-lg text-center" href={web.includes("https://") ? `${web}` : `https://${web}/`}>
                                    <span className="color-texto-web font-body">{web.includes("https://") ? web.replace("https://", "") : web}</span>
                                </motion.a>
                            }
                            {
                                projectLogo?.linkbuttom &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="px-4 py-4 border-2 border-black rounded-2xl uppercase mt-4 hover:bg-black hover:text-white items-center justify-center flex w-[300px]"
                                    href={projectLogo?.linkbuttom?.link}
                                >
                                    {projectLogo?.linkbuttom?.name}
                                </motion.a>
                            }
                            {
                                linkbuttom &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                                    className="px-4 py-4 border-2 border-black rounded-2xl uppercase mt-4 hover:bg-black hover:text-white items-center justify-center flex w-[300px]"
                                    href={linkbuttom?.link}
                                >
                                    {linkbuttom?.name}
                                </motion.a>
                            }
                        </div>
                    </div>
                </div>

                <section className="flex justify-center mt-3">
                    <div className="md:w-2/5 sm:w-full flex items-center justify-center">
                        <motion.img
                            initial={{ opacity: 0, y: -100 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, ease: "easeInOut" }}
                            className="md:scale-100 sm:scale-50 md:max-w-[200px] max-h-[100px] sm:max-w-[250px]"
                            src={logo_oxxo} alt="logo" />
                    </div>
                </section>

                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                    className="flex flex-row flex-wrap justify-center items-center p-5 md:w-3/5 sm:w-full">
                    <a
                        className={socialStyle} href={"tel:" + phone}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={phoneImg} alt="Teléfono" />
                        <p className={labelStyle}>Teléfono</p></a>
                    <a
                        className={socialStyle + ` ${animated?.whats_animated && 'animate-bounce'} `} href={"https://wa.me/" + phone}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={whatsapp} alt="Whatsapp" />
                        <p className={labelStyle}>WhatsApp</p></a>
                    <a
                        className={socialStyle} href={URL.createObjectURL(url)} download={`${String(name + lastname).normalize("NFD").replace(/[\u0300-\u036f]/g, "")}.vcf`}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={contact} alt="Contacto" />
                        <p className={labelStyle}>Contacto</p></a>
                    <a
                        className={socialStyle} href={"mailto:" + email}><motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={email_} alt="Email" />
                        <p className={labelStyle}>Email</p></a>
                    {linkedin && <a className={socialStyle} href={linkedin.includes("https://www.linkedin.com/in") ? linkedin : "https://www.linkedin.com/in/" + linkedin}><motion.img
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ type: "spring", stiffness: 400, damping: 17 }}
                        src={linkedinImg} alt="Linkedin" />
                        <p className={labelStyle}>LinkedIn</p></a>}
                    {facebook && <a className={socialStyle} href={
                        typeOfDevice === "Android" ? (facebook.includes("https://www.facebook.com/") ? `fb://facewebmodal/f?href=${facebook}` : `fb://facewebmodal/f?href=https://www.facebook.com/${facebook}/`) : (
                            typeOfDevice === "iOS" ? (facebook.includes("https://www.facebook.com/") ? `fb://profile/${facebook.replace("https://www.facebook.com/", "")}` : `fb://profile/${facebook}`) : (facebook.includes("https://www.facebook.com/") ? `${facebook}` : `https://www.facebook.com/${facebook}/`)
                        )
                    } >
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={facebookp} alt="Facebook" />
                        <p className={labelStyle}>Facebook</p></a>}
                    {instagram && <a className={socialStyle} href={instagram.includes("https://www.instagram.com/") ? `${instagram}` : `https://instagram.com/${instagram}`} >
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={instagramp} alt="Instagram" />
                        <p className={labelStyle}>Instagram</p></a>}
                    {twitter && <a className={socialStyle} href={twitter.includes("https://twitter.com/") ? `${twitter}` : `https://twitter.com/${twitter}`}>
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={twitterp} alt="Twitter" />
                        <p className={labelStyle}>X (Twitter)</p></a>}
                    {video && <a className={socialStyle} href={`${video}`} target="_blank" rel="noreferrer">
                        <motion.img
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                            transition={{ type: "spring", stiffness: 400, damping: 17 }}
                            src={videop} alt="VIDEO" />
                        <p className={labelStyle}>VIDEO</p></a>}
                    {microsoftteams &&
                        <a className={socialStyle}
                            href={microsoftteams.includes("https://teams.microsoft.com/l/") ? microsoftteams : "https://teams.microsoft.com/l/" + microsoftteams}
                            target="_blank"
                            rel="noreferrer">
                            <motion.img
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                src={microsoftteamslg} alt="Microsoft Teams" />
                            <p className={labelStyle}>MS Teams</p>
                        </a>
                    }
                </motion.div>
            </section>
        </main>
    )
}

export default MainCointainer