import React, { useMemo, useState } from "react"
import { motion } from "framer-motion"
import bhtrademarket from "../../../assets/templates/bh/bhlogo.png"
import profile from "../../../assets/social_icons/profile.png"
import bhlogo1 from "../../../assets/templates/bh/bhlogo_1.png"
import bhlogo2 from "../../../assets/templates/bh/bhlogo_2.png"
import generateVcard from "../../../functions/generateVcard"
import QrModal from "../../../components/QrModal/QrModal"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import CarouselContainer from "../../../components/Slider/CarouselCotainer"

// Configuración del carrusel
const sliderSettings = {
    dots: false, // Muestra los puntos de navegación
    infinite: false, // Permite un desplazamiento infinito
    speed: 500, // Velocidad de transición en milisegundos
    slidesToShow: 3, // Número de elementos mostrados a la vez
    slidesToScroll: 1, // Número de elementos desplazados en cada cambio
    responsive: [
        {
            breakpoint: 768,
            settings: {
                slidesToShow: 2, // Cambia el número de elementos mostrados en dispositivos más pequeños
            },
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 2, // Cambia el número de elementos mostrados en dispositivos aún más pequeños
            },
        },
    ],
};


const socialStyle = "flex flex-col drop-shadow-lg items-center justify-center"
const labelStyle = "text-center sm:text-lg text-white font-bold font-body uppercase truncate"
const imageStyle = "xl:w-[110px] xl:h-[110px] sm:w-12 sm:h-12 p-2"
const cardStyle = "bg-[#112FCB] rounded-lg py-2"
const silderStyle = "sm:w-4/5 md:w-full mx-auto track-config slide-config"

const MainCointainer = ({ user, typeOfDevice, projectLogo }) => {

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, job, video, animated, microsoftteams, augmented_reality } = user

    const [showQr, setShowQr] = useState(false)

    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])

    const handleQR = () => {
        setShowQr(!showQr)
    }


    return (
        <main className="flex flex-col justify-evenly md:p-20 sm:p-5 bg-black xl:h-full lg:h-full md:h-screen xs:h-auto">
            <section className="flex flex-col justify-center items-center">
                <motion.img
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className="mx-auto drop-shadow-lg" src={projectLogo ? projectLogo : bhtrademarket} alt="bhtrademarket" />
            </section>

            <section className="flex md:justify-center md:flex-col sm:flex-col sm:justify-center items-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                {/* INFORMACION PERSONAL */}
                <div className="relative w-full">
                    <div className="absolute bg-gradient-to-tl from-[#0E34FF] via-[#007AFF] to-[#00B2FF] w-full rounded-lg shadow-lg top-1/4 h-5/6">
                    </div>
                    <>
                        <motion.div
                            className="flex flex-col items-center justify-center">
                            <img className="md:w-56 sm:w-40 rounded-full mx-auto drop-shadow-lg border-4 border-name_bh"
                                src={photo ? photo : profile} alt="profile" />
                            {/* <QR></QR> */}
                            <motion.button
                                onClick={handleQR}
                                title="Ver QR"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ filter: "none" }}
                                className="relative flex flex-col items-center justify-center bg-gradient-to-r from-blue-800 to-indigo-900 rounded-full p-2 -top-5">
                                <svg width="20" height="20" fill="none" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                                </svg>
                            </motion.button>
                        </motion.div>
                        {/* DATOS */}
                        <div className="leading-tight flex flex-col justify-center items-center">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-name_bh font-heading">{name} {lastname}</motion.h1>
                            {
                                job &&
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center text-white uppercase font-body">{job}</motion.h2>

                            }
                            {web &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="md:text-xl sm:text-sm" href={web.includes("https://") ? `${web}` : `https://${web}/`}>
                                    <span className="text-white font-body">{web.includes("https://") ? web.replace("https://", "") : web}</span>
                                </motion.a>
                            }
                            {
                                <motion.button
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="bg-button_bh hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-lg mt-5"
                                >
                                    <a
                                        href={URL.createObjectURL(url)}
                                        download={`${String(name + lastname)
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                        className="text-white"
                                    >
                                        GUARDAR CONTACTO
                                    </a>
                                </motion.button>
                            }

                        </div>
                    </>
                </div>
            </section>

            <section className="mt-16 mb-8">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                >

                    <CarouselContainer
                        socialStyle={socialStyle}
                        imageStyle={imageStyle}
                        sliderSettings={sliderSettings}
                        email={email}
                        phone={phone}
                        linkedin={linkedin}
                        microsoftteams={microsoftteams}
                        facebook={facebook}
                        instagram={instagram}
                        twitter={twitter}
                        video={video}
                        animated={animated}
                        typeOfDevice={typeOfDevice}
                        labelStyle={labelStyle}
                        cardStyle={cardStyle}
                        silderStyle={silderStyle}
                        augmented_reality={augmented_reality}
                    />
                </motion.div>
            </section>

            <section className="flex flex-col justify-center items-center">
                <h2 className="text-[#00FA9D] text-center font-bold font-body xl:text-3xl xs:text-xl uppercase">Las ultimas novedades</h2>
                <motion.a
                    whileHover={{ scale: 1.02 }}
                    whileTap={{ scale: 0.9 }}
                    className="bg-gradient-to-tl from-[#0E34FF] from-90% via-[#007AFF] to-[#00B2FF] w-full p-4 text-[#00FA9D] rounded-lg xl:mt-8 xs:mt-4 flex flex-row items-center"
                    href="https://catalogo.trademarket.com.mx/"
                >
                    <span className="xl:w-1/2 xs:w-full flex items-center justify-center">
                        <img src={bhlogo2} alt="bhtrademarket" className="md:block xs:hidden" />
                        <img src={bhlogo1} alt="bhtrademarket" className="md:hidden xs:block md:w-20 md:h-20" />
                    </span>
                    <div className="flex xl:w-1/2 xs:w-full">
                        <span className="xl:text-left xs:text-left xl:text-3xl lg:text-2xl xs:text-xl font-bold font-body uppercase xl:w-[350px] xs:w-full">Nuestro catálogo de productos</span>
                    </div>
                </motion.a>
            </section>
        </main >
    )
}

export default MainCointainer