import React, { useContext, useEffect, useState } from "react";
import { FirebaseFuntions } from "../../context/FirebaseContext";
import { Table, Button, Tag, Modal, Form, Input } from 'antd';
import { CheckCircleOutlined, EditOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import logo_makinn from "../../assets/makinn/makinn.png";
import { DatePicker, TimePicker } from "antd";
import locale from "antd/es/date-picker/locale/es_ES";
import es from "antd/es/date-picker/locale/es_ES";
import Swal from "sweetalert2";

const ListDating = () => {

  const { loginFlag, searchUser, authID, authStatus, updateStatusCited, updateDateCited } = useContext(FirebaseFuntions)
  const { projectId, userId } = useParams()
  const [user, setUser] = useState("")
  const [loader, setLoader] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [newTime, setNewTime] = useState("");
  const [newDate, setNewDate] = useState("");
  const [idCited, setIdCited] = useState("");

  const showModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  useEffect(() => {
    if (userId || projectId) {
      const data = searchUser(userId, projectId);
      data.then((res) => {
        setUser(res)
      })
    }
  }, [userId, projectId, searchUser])

  const handleLoader = () => {
    setTimeout(() => {
      setLoader(true)
    }, 2000)
  }

  useEffect(() => {
    authStatus()
    handleLoader()
  }, [authStatus])

  const data = user?.cited?.map((item, index) => {
    //reverse para mostrar primero los ultimos
    // Extrae los segundos del objeto created_at y crea un objeto Date
    const date = new Date(item.created_at.seconds * 1000);
    // Formatea la fecha en dd/mm/yyyy
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Los meses son 0-indexed
    const year = date.getFullYear();
    // Formatea la hora en hh:mm:ss
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const formattedDateTime = `${day}/${month}/${year} en un horario de ${hours}:${minutes} ${hours >= 12 ? 'pm' : 'am'}`;
    return {
      key: index,
      nombre: item?.name,
      correo: item?.email,
      telefono: item?.phone,
      mensaje: item?.message,
      fecha: formattedDateTime,
      status: item?.status_cited,
      id: item?.id
    }
  });

  const columns = [
    {
      title: 'NOMBRE',
      dataIndex: 'nombre',
      key: 'nombre',
      fixed: 'left',
    },
    {
      title: 'CORREO ELECTRÓNICO',
      dataIndex: 'correo',
      key: 'correo',
    },
    {
      title: 'TELÉFONO',
      dataIndex: 'telefono',
      key: 'telefono',
      sorter: (a, b) => a.telefono.length - b.telefono.length,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'MENSAJE',
      dataIndex: 'mensaje',
      key: 'mensaje',
    },
    {
      title: 'FECHA',
      dataIndex: 'fecha',
      key: 'fecha',
      sorter: (a, b) => new Date(a.fecha) - new Date(b.fecha),
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'ESTADO',
      dataIndex: 'status',
      key: 'status',
      sortDirections: ['descend', 'ascend'],
      render: (status) => {
        switch (status) {
          case 0:
            return <Tag color="error">PENDIENTE</Tag>
          case 1:
            return <Tag color="success">CONFIRMADA</Tag>
          default:
            return <Tag color="warning">¡ERROR!</Tag>
        }
      }
    },
    {
      title: 'ACCIONES',
      render: (record) => {
        return (
          <div className="flex flex-row justify-center items-center space-x-3">
            <Button
              icon={<CheckCircleOutlined />}
              disabled={record.status === 1}
              type={record.status === 1 ? "default" : "primary"}
              onClick={() => handleStatusCited(record?.id, record?.nombre, record?.correo, record?.fecha)}
            />
            <Button icon={<EditOutlined />}
              disabled={record.status === 1}
              onClick={() => showEditModal(record)}
            />
          </div>
        );
      },
    }
  ];

  const handleStatusCited = async (id, nombre, correo, fecha) => {
    try {
      const result = await Swal.fire({
        title: '¿Confirmar cita?',
        text: "¡El estado ya no podrá ser modificado!",
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3E3CDF',
        cancelButtonColor: '#FF4D4F',
        confirmButtonText: '¡Sí, actualizar!',
        cancelButtonText: 'Cancelar'
      });
      if (result.isConfirmed) {
        try {
          const response = await updateStatusCited({ projectId: projectId, userId: userId, id: id, nombre: nombre, correo: correo, fecha: fecha, numero: user?.phone });
          await Swal.fire({
            title: response,
            icon: 'success',
            confirmButtonColor: '#3E3CDF',
            confirmButtonText: 'Aceptar'
          });
          const res = await searchUser(userId, projectId);
          setUser(res);
        } catch (confirmationError) {
          console.error(confirmationError, "Error en el proceso de confirmación");
          Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al actualizar el estado de la cita o los datos del usuario. Inténtalo de nuevo.',
            icon: 'error',
            confirmButtonColor: '#FF4D4F',
            confirmButtonText: 'Aceptar'
          });
        }
      }
    } catch (error) {
      console.error("Error en el proceso de confirmación:", error);
    }
  };

  const showEditModal = (userData) => {
    setName(userData.nombre);
    setEmail(userData.correo);
    setDate(userData.fecha);
    setIdCited(userData.id);
    setIsModalOpen(true);
  }

  const handleUpdateDateCited = async (idCited) => {
    try {
      const result = await Swal.fire({
        title: '¿Actualizar fecha y hora de la cita?',
        text: "¡El estado ya no podrá ser modificado!",
        icon: 'question',
        showCancelButton: true,
        reverseButtons: true,
        confirmButtonColor: '#3E3CDF',
        cancelButtonColor: '#FF4D4F',
        confirmButtonText: '¡Sí, actualizar!',
        cancelButtonText: 'Cancelar'
      });
      if (result.isConfirmed) {
        // Combinar newDate y newTime en un objeto Date
        const selectedDate = newDate.toDate();  // Convert Moment date to JavaScript Date
        const selectedTime = newTime.toDate();  // Convert Moment time to JavaScript Date
        const combinedDateTime = new Date(
          selectedDate.getFullYear(),
          selectedDate.getMonth(),
          selectedDate.getDate(),
          selectedTime.getHours(),
          selectedTime.getMinutes(),
          selectedTime.getSeconds()
        );
        const timestamp = combinedDateTime.getTime();
        data.timestamp = timestamp;
        try {
          const response = await updateDateCited({
            id: idCited,
            projectId: projectId,
            userId: userId,
            timestamp: timestamp // Enviar el timestamp en lugar de newDate y newTime por separado
          });
          await Swal.fire({
            title: response,
            icon: 'success',
            confirmButtonColor: '#3E3CDF',
            confirmButtonText: 'Aceptar'
          });
          const res = await searchUser(userId, projectId);
          setIsModalOpen(false);
          setUser(res);
        } catch (confirmationError) {
          console.error(confirmationError, "Error en el proceso de confirmación");
          Swal.fire({
            title: 'Error',
            text: 'Hubo un problema al actualizar el estado de la cita o los datos del usuario. Inténtalo de nuevo.',
            icon: 'error',
            confirmButtonColor: '#FF4D4F',
            confirmButtonText: 'Aceptar'
          });
        }
      }
    } catch (error) {
      console.error("Error en el proceso de confirmación:", error);
    }
  }

  return (
    <main className="p-14">
      {loginFlag !== null ? (
        loader && authID === user?.authID ? (
          <motion.div
            initial={{ opacity: 0, scale: 0.5 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              default: {
                duration: 0.4,
                ease: [0, 0.71, 0.2, 1.01]
              },
              opacity: {
                duration: 0.4
              }
            }}>
            <div className="flex flex-row items-center">
              <Link to={`/${projectId}/${userId}`}>
                <svg width="35" height="35" fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path d="M19 12H5"></path>
                  <path d="m12 19-7-7 7-7"></path>
                </svg>
              </Link>
              <h1 className="text-3xl font-semibold text-start ml-2">CITAS AGENDADAS</h1>
            </div>
            <section className="mt-7 w-full overflow-auto">
              <Table
                className="table-ant"
                dataSource={data}
                columns={columns}
                pagination={{
                  pageSizeOptions: [10, 20, 40, 80, 100],
                  position: ['bottomCenter'],
                  pageSize: 6,
                  total: data?.length,
                  showTotal: (total, range) => `Mostrando citas ${range[0]} a ${range[1]} de un total de ${total}`,
                }}
              />
            </section>
          </motion.div>
        ) : (
          <AnimatePresence>
            <div className="md:w-4/12 sm:w-auto left-1/2 -translate-x-2/4 translate-y-full text-center absolute">
              <motion.img
                initial={{ rotateY: 0 }}
                animate={{ rotateY: 360 }}
                transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                exit={{ opacity: 0 }}
                src={logo_makinn}
                alt="logo makinn"
                className="mx-auto mb-5"
                width={150} height={150}
              />
              <p className="uppercase text-lg text-black">Cargando</p>
            </div>
          </AnimatePresence>
        )
      ) : (
        <div className="md:w-4/12 sm:w-auto top-1/2 left-1/2 -translate-x-2/4 -translate-y-2/4 text-center absolute">
          <h1 className="uppercase font-bold font-heading">Inicia sesión para poder ver tus citas</h1>
        </div>
      )}

      <Modal open={isModalOpen} centered width={470} onCancel={showModal}
        //manda el id de la cita para actualizar la fecha y hora
        onOk={() => handleUpdateDateCited(idCited)}
        okText="Aceptar" cancelText="Cancelar">
        <h1 className="text-2xl font-bold mb-5 uppercase">Editar cita</h1>
        <Form
          name="basic"
          labelCol={{
            span: 7
          }}
          labelAlign="left"
          wrapperCol={{
            span: 25,
          }}
          style={{
            maxWidth: "auto",
          }}
          autoComplete="off"
        >
          <Form.Item
            label="Nombre"
          >
            <Input
              disabled
              value={name}
            />
          </Form.Item>
          <Form.Item
            label="Correo"
          >
            <Input disabled
              value={email}
            />
          </Form.Item>
          <Form.Item
            label="Fecha Agendada"
          >
            <Input disabled
              value={date}
            />
          </Form.Item>
          <Form.Item label="Nueva Fecha">
            <DatePicker
              className="p-2 w-1/2"
              locale={es}
              placeholder="Fecha"
              onChange={(newDate) => setNewDate(newDate)}
              disabledDate={(current) => current && current.valueOf() < Date.now() - 86400000}
              size="middle"
            />
          </Form.Item>
          <Form.Item label="Nueva Hora">
            <TimePicker
              className="p-2 w-1/2"
              locale={locale}
              placeholder="Hora"
              onChange={(newDate) => setNewTime(newDate)}
              size="middle"
              showNow={false}
              format="HH:mm"
              disabledTime={() => {
                return {
                  disabledHours: () => [0, 1, 2, 3, 4, 5, 6, 7, 8, 17, 18, 19, 20, 21, 22, 23],
                };
              }}
            />
          </Form.Item>
        </Form>
      </Modal>


    </main>
  );
};

export default ListDating;
