import React, { useMemo, useState } from "react"
import { motion } from "framer-motion"
import procaps_logo from "../../../assets/templates/procaps/procaps_logo.png"
import profile from "../../../assets/social_icons/profile.png"
import generateVcard from "../../../functions/generateVcard"
import QrModal from "../../../components/QrModal/QrModal"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import vadecum from "../../../assets/templates/procaps/vadecum.png"
import Carousel from "../../../components/Slider/Carousel"

const MainCointainer = ({ user, typeOfDevice, projectLogo }) => {

    const { name, lastname, email, phone, linkedin, photo, facebook, instagram, twitter, web, job, video, microsoftteams, animated, location } = user
    const [showQr, setShowQr] = useState(false)
    const url = useMemo(() => {
        const data = generateVcard(user)
        return data
    }, [user])
    const handleQR = () => {
        setShowQr(!showQr)
    }
    const socialStyle = "flex justify-center drop-shadow-lg mt-2"
    const imageStyle = "xl:w-[120px] xl:h-[120px] sm:w-20 sm:h-20 flex content-center p-2"

    const socialLinks = [
        phone,
        email,
        linkedin,
        facebook,
        instagram,
        twitter,
        video,
        microsoftteams
    ].filter(link => link !== undefined && link !== null);

    let infinite = false;

    if (socialLinks.length >= 4) {
        infinite = true;
    }
    if (socialLinks.length <= 3) {
        infinite = false;
    }

    const sliderSettings = {
        dots: false, // Muestra los puntos de navegación
        infinite: infinite, // Permite un desplazamiento infinito
        speed: 500, // Velocidad de transición en milisegundos
        slidesToShow: 3, // Número de elementos mostrados a la vez
        slidesToScroll: 1, // Número de elementos desplazados en cada cambio
        // centerMode: false, // Centra el elemento activo
        centerPadding: "0px",
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 3, // Cambia el número de elementos mostrados en dispositivos más pequeños
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3, // Cambia el número de elementos mostrados en dispositivos aún más pequeños
                },
            },
        ],
    };

    return (
        <main className="flex flex-col justify-evenly md:p-20 sm:p-5 bg-white xl:h-full lg:h-full md:h-screen xs:h-auto">
            <section className="flex flex-col justify-center items-center">
                <motion.img
                    initial={{ opacity: 0, y: -50 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 1, ease: "easeInOut" }}
                    className="mx-auto" src={projectLogo ? projectLogo : procaps_logo} alt="carnot" />
            </section>

            <section className="flex md:justify-center md:flex-col sm:flex-col sm:justify-center items-center">
                <QrModal
                    showQr={showQr}
                    setShowQr={setShowQr}
                    url={window.location.href}
                    fullname={`${name} ${lastname}`}
                />
                {/* INFORMACION PERSONAL */}
                <div className="relative w-full">
                    <div className="absolute bg-procaps-primary border border-procaps-primary w-full rounded-lg shadow-lg top-1/4" style={{ height: "78%" }}>
                        <motion.div
                            initial={{ opacity: 0, y: -50 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}>
                        </motion.div>
                    </div>
                    <>
                        <motion.div
                            className="flex flex-col items-center justify-center">
                            <img className="md:w-56 md:mt-0 sm:mt-5 sm:w-40 rounded-full mx-auto drop-shadow-lg border-4 border-procaps-secondary"
                                src={photo ? photo : profile} alt="profile" />
                            {/* <QR></QR> */}
                            <motion.button
                                onClick={handleQR}
                                title="Ver QR"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.9 }}
                                style={{ filter: "none" }}
                                className="relative flex flex-col items-center justify-center bg-procaps-secondary rounded-full p-2 -top-5">
                                <svg width="20" height="20" fill="none" stroke="#ffff" strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                    <path fill="#ffff" stroke="none" d="M19.125 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M15.375 12.75h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 19.5h-2.25a.375.375 0 0 0-.375.375v2.25c0 .207.168.375.375.375h2.25a.375.375 0 0 0 .375-.375v-2.25a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M22.125 12.75h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M14.625 20.25h-1.5a.375.375 0 0 0-.375.375v1.5c0 .207.168.375.375.375h1.5a.375.375 0 0 0 .375-.375v-1.5a.375.375 0 0 0-.375-.375Z"></path>
                                    <path fill="#ffff" stroke="none" d="M19.125 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M21 2.25h-6.75a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75H21a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 4.5h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 2.25H3a.75.75 0 0 0-.75.75v6.75c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75V3a.75.75 0 0 0-.75-.75Z"></path>
                                    <path fill="#ffff" stroke="none" d="M7.875 15.75h-3a.375.375 0 0 0-.375.375v3c0 .207.168.375.375.375h3a.375.375 0 0 0 .375-.375v-3a.375.375 0 0 0-.375-.375Z"></path>
                                    <path d="M9.75 13.5H3a.75.75 0 0 0-.75.75V21c0 .414.336.75.75.75h6.75a.75.75 0 0 0 .75-.75v-6.75a.75.75 0 0 0-.75-.75Z"></path>
                                </svg>
                            </motion.button>
                        </motion.div>
                        {/* DATOS */}
                        <div className="leading-tight mb-4 flex flex-col justify-center items-center space-y-3 xs:mt-3 lg:mt-0">
                            <motion.h1
                                initial={{ opacity: 0, y: -50 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 1, ease: "easeInOut", delay: 0.8 }}
                                className="md:text-4xl sm:text-2xl font-bold text-center text-white  font-heading">{name} {lastname}</motion.h1>
                            {
                                job &&
                                <motion.h2
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1 }}
                                    className="md:text-2xl sm:text-lg text-center text-white uppercase font-body">{job}</motion.h2>

                            }
                            {
                                <motion.button
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="bg-procaps-secondary text-white font-bold py-2 px-4 rounded-lg text-center"
                                >
                                    <a
                                        href={URL.createObjectURL(url)}
                                        download={`${String(name + lastname)
                                            .normalize("NFD")
                                            .replace(/[\u0300-\u036f]/g, "")}.vcf`}
                                        className="text-white font-body"

                                    >
                                        GUARDAR CONTACTO
                                    </a>
                                </motion.button>
                            }
                            {web &&
                                <motion.a
                                    initial={{ opacity: 0, y: -50 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{ duration: 1, ease: "easeInOut", delay: 1.3 }}
                                    className="text-xl text-center" href={web.includes("https://") ? `${web}` : `https://${web}/`}>
                                    <span className="text-white">{web.includes("https://") ? web.replace("https://", "") : web}</span>
                                </motion.a>
                            }
                        </div>
                    </>
                </div>
            </section>

            {location === null || location === undefined || location === "0" ?
                null
                :
                <motion.p
                    initial={{ opacity: 0, scale: 0.5 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 1.5 }}
                    className="bg-procaps-primary text-white border-solid border-procaps-primary w-full p-5 rounded-lg text-xl inline-flex items-center font-medium mt-10  text-center justify-center">
                    <svg width={80} height={50} fill="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M12 1.5c-4.14 0-7.5 3.024-7.5 6.75 0 6 7.5 14.25 7.5 14.25s7.5-8.25 7.5-14.25c0-3.726-3.36-6.75-7.5-6.75ZM12 12a3 3 0 1 1 0-5.999A3 3 0 0 1 12 12Z" />
                    </svg>
                    {location === "1" ? "Guatemala | 15 Avenida 17-30 Zona 13 Torre Mundial 2 Nivel 10 Oficina 1001" :
                        location === "2" ? "El Salvador | Avenida el espino, calle el almendro, Bulevar Sur, Santa Elena, Santa Elena, La Libertad, El Salvador" :
                            location === "3" ? "Honduras | Boulevard Morazán, Torre Morazán #2 piso 17 local 21707" :
                                location === "4" ? "Nicaragua | Km 10.5 Carretera Sur, Plaza Eg-tracsa" :
                                    "Sin ubicación"}
                </motion.p>
            }

            <section className="mt-5">
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 1, ease: "easeInOut", delay: 1.6 }}
                    className="slider-procaps  custom-container"
                >
                    <Carousel
                        socialStyle={socialStyle}
                        imageStyle={imageStyle}
                        sliderSettings={sliderSettings}
                        email={email}
                        phone={phone}
                        linkedin={linkedin}
                        microsoftteams={microsoftteams}
                        facebook={facebook}
                        instagram={instagram}
                        twitter={twitter}
                        video={video}
                        animated={animated}
                        typeOfDevice={typeOfDevice}
                    />
                </motion.div>
            </section>

            <section className="flex justify-center items-center mt-5">
                <a href="https://www.procapslaboratorios.com/farma" target="_blank" rel="noopener noreferrer">
                    <motion.img
                        initial={{ opacity: 0, y: -50 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 1, ease: "easeInOut", delay: 1.9 }}
                        className="mx-auto drop-shadow-lg" src={vadecum} alt="carnot" />
                </a>
            </section>

        </main >
    )
}

export default MainCointainer