import React, { useContext } from "react"
import { motion } from "framer-motion"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import { Link, useNavigate } from "react-router-dom"
const Header = ({ props }) => {

    const { userId, projectId } = props
    const { userLogout } = useContext(FirebaseFuntions)
    const navigate = useNavigate()

    return (
        <div className="flex justify-end items-center">
            {projectId === "promo" &&
                <motion.div
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.9 }}
                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                    className="mr-5"
                >
                    <Link to={`/${projectId}/${userId}/list-dating`} title="Citas agendadas">
                        <svg width="27" height="27" fill="#0077ED" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.5 21.375A1.125 1.125 0 0 0 2.625 22.5h18.75a1.125 1.125 0 0 0 1.125-1.125V8.25h-21v13.125Zm15-11.438a.188.188 0 0 1 .188-.187h1.875a.188.188 0 0 1 .187.188v1.874a.188.188 0 0 1-.188.188h-1.875a.188.188 0 0 1-.187-.188V9.938Zm0 3.75a.188.188 0 0 1 .188-.187h1.875a.188.188 0 0 1 .187.188v1.874a.188.188 0 0 1-.188.188h-1.875a.188.188 0 0 1-.187-.188v-1.874Zm-3.75-3.75a.188.188 0 0 1 .188-.187h1.874a.188.188 0 0 1 .188.188v1.874a.188.188 0 0 1-.188.188h-1.874a.188.188 0 0 1-.188-.188V9.938Zm0 3.75a.188.188 0 0 1 .188-.187h1.874a.188.188 0 0 1 .188.188v1.874a.188.188 0 0 1-.188.188h-1.874a.188.188 0 0 1-.188-.188v-1.874Zm0 3.75a.188.188 0 0 1 .188-.187h1.874a.188.188 0 0 1 .188.188v1.875a.188.188 0 0 1-.188.187h-1.874a.188.188 0 0 1-.188-.188v-1.875ZM9 13.688a.188.188 0 0 1 .188-.187h1.874a.188.188 0 0 1 .188.188v1.874a.188.188 0 0 1-.188.188H9.188A.188.188 0 0 1 9 15.562v-1.874Zm0 3.75a.188.188 0 0 1 .188-.187h1.874a.188.188 0 0 1 .188.188v1.875a.188.188 0 0 1-.188.187H9.188A.188.188 0 0 1 9 19.312v-1.875Zm-3.75-3.75a.188.188 0 0 1 .188-.187h1.875a.188.188 0 0 1 .187.188v1.874a.188.188 0 0 1-.188.188H5.438a.188.188 0 0 1-.187-.188v-1.874Zm0 3.75a.188.188 0 0 1 .188-.187h1.875a.188.188 0 0 1 .187.188v1.875a.188.188 0 0 1-.188.187H5.438a.188.188 0 0 1-.187-.188v-1.875Z"></path>
                            <path d="M21.375 3h-2.621V1.5h-2.25V3H7.496V1.5h-2.25V3H2.625A1.116 1.116 0 0 0 1.5 4.114V6.75h21V4.114A1.117 1.117 0 0 0 21.375 3Z"></path>
                        </svg>
                    </Link>
                </motion.div>
            }
            <motion.div
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
            >
                <Link to={`/${projectId}/${userId}/edit`} title="Editar perfil">
                    <svg width="27" height="27" fill="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                        <path d="M9.954 2.213a9.99 9.99 0 0 1 4.091-.002A3.994 3.994 0 0 0 16 5.073a3.993 3.993 0 0 0 3.457.26A9.99 9.99 0 0 1 21.5 8.88a3.994 3.994 0 0 0-1.5 3.124 3.99 3.99 0 0 0 1.502 3.124 10.042 10.042 0 0 1-2.046 3.543 3.993 3.993 0 0 0-4.76 1.468 3.993 3.993 0 0 0-.65 1.653 9.992 9.992 0 0 1-4.09.004A3.993 3.993 0 0 0 8 18.93a3.992 3.992 0 0 0-3.457-.26A9.99 9.99 0 0 1 2.5 15.124a3.994 3.994 0 0 0 1.106-4.856 3.993 3.993 0 0 0-1.108-1.39 10.043 10.043 0 0 1 2.046-3.543 3.993 3.993 0 0 0 4.76-1.468 4 4 0 0 0 .65-1.653v-.001ZM12 15.003a3 3 0 1 0 0-6 3 3 0 0 0 0 6Z"></path>
                    </svg>
                </Link>
            </motion.div>
            <motion.button
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.9 }}
                transition={{ type: "spring", stiffness: 400, damping: 17 }}
                className="bg-black text-white text-sm m-5 px-4 py-2 rounded-xl flex flex-row items-center uppercase focus:outline-none font-body"
                onClick={() => {
                    userLogout()
                    setTimeout(() => {
                        navigate(`/${projectId}/${userId}`)
                    }, 500);
                }}
            >
                <span className="mr-4">
                    Cerrar sesión
                </span>
                <svg width="27" height="27" fill="#fff" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M4 18h2v2h12V4H6v2H4V3a1 1 0 0 1 1-1h14a1 1 0 0 1 1 1v18a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-3Zm2-7h7v2H6v3l-5-4 5-4v3Z"></path>
                </svg>
            </motion.button>
        </div>
    );
};

export default Header;
