import React, { useContext, useEffect, useState, useMemo } from "react"
import { motion, AnimatePresence } from "framer-motion"
import { Link, useParams } from "react-router-dom"
import backtoa from "../../assets/img/return.svg"
import profile from "../../assets/social_icons/profile.png"
import { FirebaseFuntions } from "../../context/FirebaseContext"
import { Upload, Button } from "antd"
import { UploadOutlined } from "@ant-design/icons"
import ImgCrop from "antd-img-crop"
import logo from "../../assets/makinn/makinn.png"
import { Input, Checkbox } from "antd"
import xlogo from "../../assets/social_icons/xlogo.png"
import web_logo from "../../assets/social_icons/web.png"
import linkedid_logo from "../../assets/social_icons/linkedin.png"
import instagram_logo from "../../assets/social_icons/instagram.png"
import facebook from "../../assets/social_icons/facebook.png"
import video_logo from "../../assets/social_icons/video.png"
import msteams_logo from "../../assets/social_icons/microsoftteams.png"
import ra_logo from "../../assets/social_icons/ra.png"
import trash from "../../assets/img/trash.svg"

const EditProfile = () => {
    const { loginFlag, authStatus, searchUser, uploadPhoto, updateUser, authID, deleteSocial, uploadBanner } = useContext(FirebaseFuntions)

    const { projectId, userId } = useParams()
    const [user, setUser] = useState("")
    const [loader, setLoader] = useState(false)
    const [photo, setPhoto] = useState({})
    const [photoFile, setPhotoFile] = useState(null)
    const [bannerFile, setBannerFile] = useState(null)
    const [socials, setSocials] = useState({})
    const [isBig, setIsBig] = useState(false)
    const [alertStatus, setAlertStatus] = useState("")
    const [updateAlert, setUpdateAlert] = useState(false)
    const [isPersonal, setIsPersonal] = useState(false)
    const [typeOfF, setTypeOfF] = useState("default")
    const [isSidebar, setIsSidebar] = useState("editar_cuenta")
    const [activeItem, setActiveItem] = useState("editar_cuenta")
    const [animatedBehavior, setAnimatedBehavior] = useState(null)
    const textDisabled = "Disponible con tema 'Default'"

    const optionTemplate = [
        { value: "promo", label: "Default" },
        { value: "luxury", label: "Elegante" },
        { value: "bh", label: "BH Trade Market", condition: () => user?.projectId === "bh" || user?.role === "superadmin" },
        { value: "voestalpine", label: "Voestalpine", condition: () => user?.projectId === "voestalpine" },
        { value: "wen", label: "Wen Rodríguez", condition: () => user?.projectId === "makinn" && user?.userId === "usuario_0" },
        { value: "carnot", label: "Carnot", condition: () => user?.projectId === "carnot" },
        { value: "procaps", label: "Procaps", condition: () => user?.projectId === "procaps" },
        { value: "ollamani", label: "Ollamani", condition: () => user?.projectId === "ollamani" },
        { value: "oxxo", label: "Oxxo", condition: () => user?.projectId === "oxxo" },
        { value: "custlog", label: "Custlog", condition: () => user?.projectId === "custlog" }

    ]

    const filteredOptions = optionTemplate.filter(option => {
        return option.condition ? option.condition() : true;
    });

    const handleItemClick = (item) => {
        setIsSidebar(item);
        setActiveItem(item);
    };

    const handleSubmit = (e) => {
        e.preventDefault()
        const datos = Array.from(new FormData(e.target))
        const obj = Object.fromEntries(datos)
        // console.log(obj);
        if (!isBig && photo.size !== 0) {
            const respuesta = updateUser({ ...obj, projectId, userId })
            respuesta.then((res) => {
                // console.log(res)
                setAlertStatus(res)
                handleAlert()
                //actualiza el estado de la información del usuario en el componente
                const data = searchUser(userId, projectId);
                data.then((res) => {
                    setUser(res)
                    document.getElementById("template").value = res.template
                    document.getElementById("location").value = res.location
                })
            })
        } else {
            const respuesta = updateUser({ ...obj, projectId, userId })
            respuesta.then((res) => {
                console.log(res)
                setAlertStatus(res)
                handleAlert()
            })
        }
        const newSocials = (obj) => {
            const newSocials = { ...socials }
            if (obj.facebook) {
                newSocials.facebook = obj.facebook
            }
            if (obj.microsoftteams) {
                newSocials.microsoftteams = obj.microsoftteams
            }
            if (obj.instagram) {
                newSocials.instagram = obj.instagram
            }
            if (obj.linkedin) {
                newSocials.linkedin = obj.linkedin
            }
            if (obj.twitter) {
                newSocials.twitter = obj.twitter
            }
            if (obj.web) {
                newSocials.web = obj.web
            }
            if (obj.video) {
                newSocials.video = obj.video
            }
            if (obj.augmented_reality) {
                newSocials.augmented_reality = obj.augmented_reality
            }
            return newSocials
        }
        setSocials(newSocials(obj))
        e.target.reset();
    }

    const handleLoader = () => {
        setTimeout(() => {
            setLoader(true)
        }, 2000)
    }

    const handleBanner = (e) => {
        const extData = String(e.type).replace("image/", ".")
        const url = URL.createObjectURL(e)
        if (e.size > 2097152) {
            setIsBig(true)
        } else {
            setIsBig(false)
            setBannerFile(url)
            const resultado = uploadBanner(e, userId + "banner" + extData, userId, projectId)
            resultado.then((res) => {
                setAlertStatus(res)
                handleAlert()
            })
        }
        return false
    }

    const handleUpload = (e) => {
        setPhoto(e)
        const extData = String(e.type).replace("image/", ".")
        const url = URL.createObjectURL(e)
        if (e.size > 2097152) {
            setIsBig(true)
        } else {
            setIsBig(false)
            setPhotoFile(url)
            const resultado = uploadPhoto(e, userId + "photo" + extData, userId, projectId)
            resultado.then((res) => {
                setAlertStatus(res)
                handleAlert()
            })
        }
        return false
    }

    const handleAlert = () => {
        setUpdateAlert(true)
        setTimeout(() => {
            return setUpdateAlert(false)
        }, 5000);
    }

    const handleDelete = (social) => {
        const datos = { ...{ social: social }, userId, projectId }
        const respuesta = deleteSocial(datos)
        respuesta.then((res) => {
            //console.log(res)
            setAlertStatus(res)
            handleAlert()
        })
        const newSocials = () => {
            const newSocials = { ...socials }
            delete newSocials[social]
            return newSocials
        }
        setSocials(newSocials())
        if (datos.social === "banner") {
            setBannerFile(null)
        }
    }

    useMemo(() => {
        if (userId || projectId) {
            const data = searchUser(userId, projectId);
            data.then((res) => {
                setUser(res)
                setIsPersonal(res.footer === "true" ? true : false)
                setTypeOfF(res.typeoffooter ? res.typeoffooter : "default")
                setSocials({ facebook: res.facebook, instagram: res.instagram, linkedin: res.linkedin, twitter: res.twitter, web: res.web, video: res.video, microsoftteams: res.microsoftteams, augmented_reality: res.augmented_reality })
                if (res !== null) {
                    setPhotoFile(res.photo)
                    setBannerFile(res.banner ? res.banner : null)
                }
                setAnimatedBehavior(res.animated)
            })
        }
    }, [userId, projectId, searchUser])

    useEffect(() => {
        authStatus()
        handleLoader()
    }, [authStatus])

    return (
        <>
            <AnimatePresence>
                {updateAlert &&
                    <motion.div
                        initial={{ opacity: 0 }}
                        whileInView={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className={`w-full bg-green-500 p-2 fixed top-0 flex justify-center items-center z-50`}>
                        <span className="text-md text-white font-medium uppercase text-center w-full">{alertStatus}</span>
                    </motion.div>
                }
            </AnimatePresence>
            <div className="md:w-9/12 sm:w-full sm:p-4 md:left-1/2 md:-translate-x-2/4 sm:top-0 sm:-translate-x-0 text-center mt-5 mb-5 absolute">
                {
                    loginFlag ? (
                        loader && authID === user.authID ? (
                            <>
                                <div className="text-left text-sm">
                                    <Link to={`/${projectId}/${userId}`} className="flex items-center"><img src={backtoa} alt="flecha de retorno" className="w-8 h-8 mr-2" /><span className="uppercase">Regresar</span></Link>
                                </div>
                                <motion.div
                                    className="shadow-xl rounded-lg flex md:flex-row sm:flex-col-reverse"
                                    initial={{ opacity: 0, scale: 0.5 }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    transition={{
                                        default: {
                                            duration: 0.4,
                                            ease: [0, 0.71, 0.2, 1.01]
                                        },
                                        opacity: {
                                            duration: 0.4
                                        }
                                    }}
                                >
                                    <div className="flex flex-col md:flex-row w-full">
                                        <div className="bg-black md:w-4/12 sm:w-full md:p-10 sm:p-6 border-radius-5-l">
                                            {
                                                (photoFile != null) ? (
                                                    <img src={photoFile} alt="foto de perfil" className="w-36 h-36 rounded-full mx-auto border-2 border-makinn_primary" />
                                                ) : (
                                                    <img src={profile} alt="foto de perfil" className="w-36 h-36 rounded-full mx-auto border-2 border-makinn_primary" />
                                                )
                                            }
                                            <div>
                                                {
                                                    user && (
                                                        <div className="flex flex-col items-center mt-6">
                                                            <h1 className="text-white text-2xl font-body w-full">{user.name} {user.lastname}</h1>
                                                        </div>
                                                    )
                                                }
                                            </div>

                                            <div className="mt-10 divide-y-2 divide-solid">
                                                <button
                                                    className={`${activeItem === 'editar_cuenta' ? 'text-white font-bold bg-makinn_primary p-4 text-lg rounded-t-lg' : 'text-white'} w-full flex items-center hover:bg-makinn_primary p-4 text-lg rounded-t-lg`}
                                                    onClick={() => handleItemClick('editar_cuenta')}
                                                >
                                                    <svg className="mr-3" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M20 16.5V21a1 1 0 0 1-1 1h-3.25"></path>
                                                        <path d="M20 8V3a1 1 0 0 0-1-1H5a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h3"></path>
                                                        <path d="M8 8h7"></path>
                                                        <path d="M11.5 22 20 11.5"></path>
                                                        <path d="M8 12h4"></path>
                                                    </svg>
                                                    Editar Cuenta
                                                </button>
                                                <button
                                                    className={`${activeItem === 'Datos de Contacto' ? 'text-white font-bold bg-makinn_primary p-4 text-lg' : 'text-white'} w-full flex items-center hover:bg-makinn_primary p-4 text-lg`}
                                                    onClick={() => handleItemClick("Datos de Contacto")}
                                                >
                                                    <svg className="mr-3" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M19 2H5a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1V3a1 1 0 0 0-1-1Z"></path>
                                                        <path d="M12 9.5a2 2 0 1 0 0-4 2 2 0 0 0 0 4Z"></path>
                                                        <path d="M15 12.5a3 3 0 0 0-6 0"></path>
                                                        <path d="M9 15.5h6"></path>
                                                        <path d="M9 18.5h3.5"></path>
                                                    </svg>
                                                    Datos de Contacto
                                                </button>
                                                <button
                                                    className={`${activeItem === 'Redes Sociales' ? 'text-white font-bold bg-makinn_primary p-4 text-lg rounded-b-lg' : 'text-white'} w-full flex items-center hover:bg-makinn_primary p-4 text-lg rounded-b-lg`}
                                                    onClick={() => handleItemClick("Redes Sociales")}
                                                >
                                                    <svg className="mr-3" width="40" height="40" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M17.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                                                        <path d="M6.5 14.5a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5Z"></path>
                                                        <path d="m15 6.787-6.33 3.836"></path>
                                                        <path d="m8.67 13.282 6.67 3.942"></path>
                                                        <path d="M17.5 16a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Z"></path>
                                                    </svg>
                                                    Redes Sociales
                                                </button>
                                            </div>
                                        </div>
                                        <div className="bg-white md:w-8/12 sm:w-full md:p-7 sm:p-5 border-radius-5 border border-black">
                                            <form className="flex flex-col" onSubmit={handleSubmit}>
                                                {isSidebar === "editar_cuenta" && (
                                                    <div id="editar_cuenta" className="flex flex-row flex-wrap items-center justify-start">
                                                        <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase" >Editar cuenta</h2>
                                                        <div className="flex md:flex-row sm:flex-col w-full md:mb-1 xs:mb-5">
                                                            <div className="m-1 md:w-1/2 sm:w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="file_input">Subir Foto<br /><span className="text-[12px]">(Cuida que la imagen sea cuadrada)</span></label>
                                                                <span className="text-sm text-black font-medium uppercase">{isBig && ("El archivo es demasiado pesado")}</span>
                                                                <ImgCrop rotationSlider={true} aspect={1} quality={0.7} modalTitle="Recorta tu imagen" modalOk="Guardar" modalCancel="Cancelar" >
                                                                    <Upload showUploadList={false} accept="image/png, image/jpeg, image/jpg" beforeUpload={handleUpload} className="w-full flex">
                                                                        <Button icon={<UploadOutlined />} className="bg-white w-full font-body">Seleccionar imagen</Button>
                                                                    </Upload>
                                                                </ImgCrop>
                                                                <p className="mt-1 text-sm text-black text-left font-body" id="file_input_help">PNG, JPG o JPGE (MAX. 2MB).</p>
                                                            </div>
                                                            <div className="m-1 md:w-1/2 sm:w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="file_input">Cambiar Logo<br /><span className="text-[12px]">(Considera una imagen rectangular)</span></label>
                                                                <span className="text-sm text-black font-medium uppercase">{isBig && ("El archivo es demasiado pesado")}</span>
                                                                {
                                                                    (bannerFile != null) ? (
                                                                        <div className="flex flex-row mr-4 items-center justify-between mx-auto w-full p-2 border-2 border-black rounded-lg">
                                                                            <img src={bannerFile} alt="banner" className="md:w-[230px] xs:w-[200px]" />
                                                                            <button className={`text-sm font-medium contents ${(bannerFile) ? 'block' : 'hidden'}`} onClick={() => handleDelete("banner")}>
                                                                                <svg width="27" height="27" fill="#000" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                    <path d="M17 6h5v2h-2v13a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V8H2V6h5V3a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v3Zm1 2H6v12h12V8Zm-9 3h2v6H9v-6Zm4 0h2v6h-2v-6ZM9 4v2h6V4H9Z"></path>
                                                                                </svg>
                                                                            </button>
                                                                        </div>
                                                                    ) : (
                                                                        <ImgCrop quality={0.4} modalTitle="Recorta tu imagen" modalOk="Guardar" modalCancel="Cancelar" aspect={15 / 2} rotationSlider aspectSlider showReset fillColor="transparent" >
                                                                            <Upload showUploadList={false} accept="image/png, image/jpeg, image/jpg" beforeUpload={handleBanner} className="w-full flex">
                                                                                <Button icon={<UploadOutlined />} className="bg-white w-full font-body">Seleccionar logo</Button>
                                                                            </Upload>
                                                                        </ImgCrop>
                                                                    )
                                                                }
                                                                <p className="mt-1 text-sm text-black text-left font-body" id="file_input_help">PNG, JPG o JPGE (MAX. 2MB).</p>
                                                            </div>
                                                        </div>
                                                        <div className="w-full flex md:flex-row sm:flex-col border-2 border-white rounded-lg my-3">
                                                            <div className="md:w-1/2 sm:w-full p-3">
                                                                <label className="w-full block text-sm text-black font-medium text-left uppercase font-body" htmlFor="template">Selecciona un tema</label>
                                                                <div className="relative">
                                                                    <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body" id="template" name="template" defaultValue={user.template} >
                                                                        {filteredOptions.map(option => (
                                                                            <option key={option.value} value={option.value}>
                                                                                {option.label}
                                                                            </option>
                                                                        ))}
                                                                    </select>
                                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                        <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m6 9 6 6 6-6" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="md:w-1/2 sm:w-full p-3">
                                                                <label className="w-full block text-sm text-black font-medium text-left uppercase font-body" htmlFor="footer">Pie de página</label>
                                                                <div className="relative">
                                                                    <select
                                                                        className={`appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body ${user?.template !== "promo" ? 'border border-gray-400 opacity-50' : ''}`}
                                                                        disabled={user?.template !== "promo"}
                                                                        id="footer" name="footer"
                                                                        defaultValue={user?.template !== "promo" ? "" : user?.footer}
                                                                        onChange={(e) => {
                                                                            setIsPersonal(e.target.value === "true" ? true : false)
                                                                        }}>
                                                                        {user?.template !== "promo" && <option value="">{textDisabled}</option>}
                                                                        <option value={true}>Si</option>
                                                                        <option value={false}>No</option>
                                                                    </select>
                                                                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                        <svg width={20} height={20} fill="none" stroke={user?.template !== "promo" ? "#9ca3af" : "#000000"}
                                                                            strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="m6 9 6 6 6-6" />
                                                                        </svg>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        {user?.template === "promo" && (
                                                            <div className={`w-full flex md:flex-row sm:flex-col border-2 border-white rounded-lg font-body my-3 ${(isPersonal) ? "block" : "hidden"}`}>
                                                                <div className="md:w-1/2 sm:w-full p-3">
                                                                    <label className="w-full block text-sm text-black font-medium text-left uppercase" htmlFor="footer">Tipo de texto personalizado</label>
                                                                    <div className="relative">
                                                                        <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body" id="typeoffooter" name="typeoffooter"
                                                                            defaultValue={user?.typeoffooter}
                                                                            onChange={(e) => {
                                                                                setTypeOfF(e.target.value)
                                                                            }}>
                                                                            <option value="defaul" >Default</option>
                                                                            <option value="personalized">Texto personalizado</option>
                                                                        </select>
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                            <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="m6 9 6 6 6-6" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="md:w-1/2 sm:w-full p-3">
                                                                    <label className="w-full block text-sm text-black font-medium text-left uppercase" htmlFor="footer">Texto personalizado</label>
                                                                    <input className={`appearance-none w-full rounded-md border border-black py-2 px-3 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body ${typeOfF !== 'personalized' && 'opacity-50 border border-gray-400'}`} type="text" id="footer_text" name="footer_text" placeholder="Texto personalizado" defaultValue={user?.footer_text} disabled={(typeOfF === "personalized") ? false : true} />
                                                                </div>
                                                            </div>
                                                        )}
                                                        {projectId === "promo" && (
                                                            <div className="w-full flex md:flex-row sm:flex-col border-2 border-white rounded-lg my-3">
                                                                <div className="md:w-1/2 sm:w-full p-3">
                                                                    <label className="w-full block text-sm text-black font-medium text-left uppercase font-body">Logo Promo Conecta</label>
                                                                    <div className="relative">
                                                                        <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                                                            id="logo_promoconecta" name="logo_promoconecta" defaultValue={user?.logo_promoconecta}
                                                                        >
                                                                            <option value={true} >Si</option>
                                                                            <option value={false}>No</option>
                                                                        </select>
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                            <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="m6 9 6 6 6-6" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {projectId === "procaps" && (
                                                            <div className="w-full flex md:flex-row sm:flex-col border-2 border-white rounded-lg my-3">
                                                                <div className="md:w-1/2 sm:w-full p-3">
                                                                    <label className="w-full block text-sm text-black font-medium text-left uppercase font-body">Localización</label>
                                                                    <div className="relative">
                                                                        <select className="appearance-none w-full rounded-md border border-black py-2 px-3 ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-makinn_primary sm:leading-6 focus:border-transparent focus:outline-none font-body"
                                                                            id="location" name="location"
                                                                            defaultValue={user?.location}
                                                                        >
                                                                            <option value={0}>Sin ubicación</option>
                                                                            <option value={1}>Guatemala</option>
                                                                            <option value={2}>El Salvador</option>
                                                                            <option value={3}>Honduras</option>
                                                                            <option value={4}>Nicaragua</option>
                                                                        </select>
                                                                        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
                                                                            <svg width={20} height={20} fill="none" stroke="#000000" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                                                <path d="m6 9 6 6 6-6" />
                                                                            </svg>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                )}
                                                {isSidebar === "Datos de Contacto" && (
                                                    <div id="Datos de Contacto" className="flex flex-row flex-wrap items-center justify-start">
                                                        <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase" >Datos de contacto</h2>
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="name">Nombre</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="name" name="name" placeholder="Nombre" defaultValue={user?.name} />
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="lastname">Apellido</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="lastname" name="lastname" placeholder="Apellido" defaultValue={user?.lastname} />

                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="phone">Whatsapp</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="number" id="phone" name="phone" placeholder="whatsapp" defaultValue={user?.phone} />
                                                        <div className="flex flex-row w-full items-center justify-center">
                                                            <Checkbox
                                                                className="m-3" type="checkbox" id="whats_animated" name="whats_animated" defaultChecked={animatedBehavior?.whats_animated ? true : false}
                                                                onChange={(e) => {
                                                                    setAnimatedBehavior({ "whats_animated": e.target.checked })
                                                                }}
                                                                value={animatedBehavior?.whats_animated ? true : false}
                                                            />
                                                            <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="whats_animated">Animación del icono</label>
                                                        </div>
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="email">Correo Electrónico</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="email" name="email" placeholder="Correo Electrónico" defaultValue={user?.email} />
                                                        <label className="w-full block mb-2 text-sm text-black font-medium text-left uppercase mt-2 font-body" htmlFor="job">Puesto de trabajo</label>
                                                        <Input
                                                            className="p-3 m-3"
                                                            type="text" id="job" name="job" placeholder="Puesto de trabajo" defaultValue={user?.job} />
                                                        <label className="mb-2 text-sm text-black font-medium text-left uppercase hidden" htmlFor="gender">Genero</label>
                                                        <select className="m-3 w-full rounded-lg p-3 focus:outline-none hidden" id="gender" name="gender">
                                                            <option value="male">Hombre</option>
                                                            <option value="female">Mujer</option>
                                                        </select>
                                                    </div>
                                                )}
                                                {isSidebar === "Redes Sociales" && (
                                                    <>
                                                        {Object.values(socials).some((value) => value) && (
                                                            <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">Redes Sincronizadas</h2>
                                                        )}
                                                        {(Object.values(socials).every((value) => value === null || value === undefined || value === '')) && (
                                                            <h2 className="text-black text-left text-lg my-3 font-bold font-heading uppercase">Redes Sociales (Opcionales)</h2>
                                                        )}
                                                        {/* REDES ASOCIADAS */}
                                                        {
                                                            user && (
                                                                <>
                                                                    {socials.web &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={web_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Sitio Web</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("web")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.web.includes("https://") ? socials.web.replace("https://", "") : socials.web}</p>
                                                                        </div>}
                                                                    {socials.linkedin &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={linkedid_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">LinkedIn</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("linkedin")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.linkedin.includes("https://www.linkedin.com/in/") ? socials.linkedin.replace("https://www.linkedin.com/in/", "") : socials.linkedin}</p>
                                                                        </div>}
                                                                    {socials.twitter &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={xlogo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">X (Twitter)</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("twitter")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.twitter.includes("https://twitter.com/") ? socials.twitter.replace("https://twitter.com/", "@") : socials.twitter}</p>
                                                                        </div>}
                                                                    {socials.instagram &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={instagram_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Instagram</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("instagram")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.instagram.includes("https://www.instagram.com/") ? socials.instagram.replace("https://www.instagram.com/", "@") : socials.instagram}</p>
                                                                        </div>}
                                                                    {socials.facebook &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={facebook} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Facebook</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("facebook")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.facebook.includes("https://www.facebook.com/") ? socials.facebook.replace("https://www.facebook.com/", "") : socials.facebook}</p>
                                                                        </div>}
                                                                    {socials.video &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={video_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Video</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("video")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.video.includes("https://www.youtube.com/watch?v=") ? socials.video.replace("https://www.youtube.com/watch?v=", "") : socials.video}</p>
                                                                        </div>}
                                                                    {socials.microsoftteams &&
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={msteams_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Microsoft Teams</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("microsoftteams")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.microsoftteams.includes("https://www.youtube.com/watch?v=") ? socials.microsoftteams.replace("https://www.youtube.com/watch?v=", "") : socials.microsoftteams}</p>
                                                                        </div>}
                                                                    {socials.augmented_reality &&
                                                                        (user?.projectId === "bh" || user?.projectId === "promo") ? (
                                                                        <div className="border-black border-2 rounded-lg p-3 my-2 w-full">
                                                                            <div className="flex justify-between">
                                                                                <div className="flex justify-between content-center">
                                                                                    <img src={ra_logo} alt="twitter" className="w-[25px] h-[25px]" />
                                                                                    <span className="flex items-center ml-2 font-body">Realidad Aumentada</span>
                                                                                </div>
                                                                                <button className="text-sm font-medium contents" title="Borrar" onClick={() => handleDelete("augmented_reality")}>
                                                                                    <img src={trash} alt="eliminar" />
                                                                                </button>
                                                                            </div>
                                                                            <p className="text-left font-body mt-2 truncate">{socials.augmented_reality}</p>
                                                                        </div>) : null}
                                                                </>
                                                            )
                                                        }
                                                        {/* REDES SOCIALES POR ASOCIAR */}
                                                        {socials.web ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="web">Sitio Web</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="web" name="web" placeholder="Sitio Web" defaultValue={socials.web} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar el url completo. Por ejemplo: https://www.misitio.com</p>
                                                            </div>
                                                        )}
                                                        {socials.video ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="video">Video</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="video" name="video" placeholder="URL del video" defaultValue={socials.video} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar el url completo.</p>
                                                            </div>
                                                        )}
                                                        {socials.linkedin ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="linkedin">LinkedIn</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="linkedin" name="linkedin" placeholder="Usuario de LinkedIn" defaultValue={socials.linkedin} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">linkedin.com/in/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.twitter ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="twitter">X (Twitter)</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="twitter" name="twitter" placeholder="Usuario de Twitter" defaultValue={socials.twitter} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">twitter.com/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.instagram ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="instagram">Instagram</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="instagram" name="instagram" placeholder="Usuario de Instagram" defaultValue={socials.instagram} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">instagram.com/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.facebook ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="facebook">Facebook</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="facebook" name="facebook" placeholder="Usuario de Facebook" defaultValue={socials.facebook} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">facebook.com/juanlopez.sanchez</span> únicamente ingresar <span className="font-bold">juanlopez.sanchez</span></p>
                                                            </div>
                                                        )}
                                                        {socials.microsoftteams ? null : (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="microsoftteams">Microsoft Teams</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="microsoftteams" name="microsoftteams" placeholder="URL de Microsoft Teams" defaultValue={socials.microsoftteams} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar únicamente el usuario, ejemplo:<br />De la dirección <span className="font-bold">teams.microsoft.com/l/chat/0/0?users=user_email
                                                                </span> únicamente ingresar <span className="font-bold">chat/0/0?users=user_email</span></p>
                                                            </div>
                                                        )}
                                                        {(!socials.augmented_reality && (projectId === "bh" || projectId === "promo")) ? (
                                                            <div className="rounded-lg p-2 w-full">
                                                                <label className="block mb-2 text-sm text-black font-medium text-left uppercase font-body" htmlFor="augmented_reality">Realidad Aumentada</label>
                                                                <Input className="py-3 px-3 mb-1" type="text" id="augmented_reality" name="augmented_reality" placeholder="URL de Microsoft Teams" defaultValue={socials.augmented_reality} />
                                                                <p className="mx-3 text-black text-[12px] text-left">Agregar el url completo.</p>
                                                            </div>
                                                        ) : null}
                                                    </>
                                                )}
                                                <motion.button
                                                    whileHover={{ scale: 1.03 }}
                                                    whileTap={{ scale: 0.9 }}
                                                    transition={{ type: "spring", stiffness: 400, damping: 17 }}
                                                    className="bg-black text-white font-bold m-3 py-2 px-4 rounded-lg uppercase focus:outline-none font-body"
                                                    type="submit"
                                                >
                                                    Guardar Cambios
                                                </motion.button>
                                            </form>
                                        </div>
                                    </div>
                                </motion.div>
                            </>
                        ) : (
                            <AnimatePresence>
                                <div className="md:w-4/12 sm:w-auto top-full left-1/2 -translate-x-2/4 translate-y-full text-center absolute">
                                    <motion.img
                                        initial={{ rotateY: 0 }}
                                        animate={{ rotateY: 360 }}
                                        transition={{ duration: 2, repeat: Infinity, type: "tween", repeatDelay: 0 }}
                                        exit={{ opacity: 0 }}
                                        src={logo}
                                        alt="logo makinn"
                                        className="mx-auto mb-5"
                                        width={150} height={150}
                                    />
                                    <p className="uppercase text-lg">Cargando</p>
                                </div>
                            </AnimatePresence>
                        )
                    ) : (
                        //centra el h1 
                        <div className="w-full h-screen flex flex-col justify-center items-center">
                            <h1 className="text-black text-center text-xl mb-5 font-bold">Necesitas iniciar sesión</h1>
                            <Link className="bg-makinn_primary rounded-xl py-3 px-5 font-bold uppercase text-white" to={`/${projectId}`} >Ir ahora</Link>
                        </div>
                    )
                }
            </div>
        </>
    )
}

export default EditProfile;
